import request from '@/utils/request'

export const postCheckout = async (data) => {
  return request({
    url: '/premium/checkout',
    method: 'post',
    data
  })
}

export const cancelPremium = async () => {
  return request({
    url: '/premium/cancel',
    method: 'post'
  })
}
