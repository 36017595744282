<template>
  <div>
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="columns is-justify-content-center">
          <div class="column is-3">
            <b-image src="https://cdn.discordapp.com/attachments/794726687136546836/930223307884752917/VIP.png"></b-image>
          </div>
        </div>
      </div>
    </section>

    <div class="container mt-4">
      <div class="columns is-multiline is-justify-content-center">
        <div v-if="!isPremium" class="column is-7">
            <!-- <p>{{ subscription.description }}</p> -->
          <h1 class="is-size-3 mb-3">Plans</h1>
          <div class="columns is-justify-content-center" id="plans">
              <div
                v-for="(plan, i) of plans"
                :key="i"
                class="card column is-6">
                <div class="card-content">
                  <header class="card-header">
                    <p class="card-header-title">
                      {{ plan.name }} - ${{ plan.price }} {{ plan.cycle }}
                    </p>
                  </header>
                  <div class="card-content">
                    {{ plan.description }}
                    <div :id="plan.id"></div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div v-else class="column is-7">
          <div class="columns">
            <div class="column is-10">
              <h1 class="is-size-5">You're already a VIP!</h1>
              <strong>Subscribed</strong>: {{ new Date(user.settings.lastPremium).toLocaleDateString() }}<br/>
              <strong>Next Charge</strong>: {{ user.settings.nextPremiumCharge ? new Date(user.settings.nextPremiumCharge).toLocaleDateString() : 'Cancelled' }}
            </div>

            <div class="column p-2">
              <b-button
                @click="cancel"
                :disabled="!user.settings.nextPremiumCharge"
                :loading="cancelling"
                label="Cancel VIP"
                type="is-danger"/>
            </div>
          </div>
        </div>

        <div class="column is-7" v-if="!isPremium">
          <h1 class="is-size-3">Compare</h1>
          <b-table
            :data="tableRows"
            :columns="tableCols"
            :mobile-cards="false">
            <b-table-column field="feature" label="Feature" v-slot="props">
              <p v-html="props.row.feature"></p>
            </b-table-column>

            <b-table-column field="free" label="Free" v-slot="props">
              <p v-html="props.row.free"></p>
            </b-table-column>

            <b-table-column field="vip" label="VIP" v-slot="props">
              <p class="has-text-success" v-html="props.row.vip"></p>
            </b-table-column>

          </b-table>
        </div>

        <div class="py-6 column is-7" v-if="!isPremium">
          <h1 class="is-size-3">FAQ</h1>
          <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, i) of collapses"
            :key="i"
            :open="faqOpen === i"
            @open="faqOpen = i">

            <template #trigger="props">
              <div class="card-header" role="button">
                <p class="card-header-title">{{ collapse.title }}</p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content">
              <div class="content">
                {{ collapse.text }}
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadScript } from '@paypal/paypal-js'
import { getGuild } from '@/api/guild'
import { cancelPremium } from '@/api/premium'

export default {
  data: () => ({
    cancelling: false,
    tableCols: [
      {
        field: 'feature',
        label: 'Features'
      },
      {
        field: 'free',
        label: 'Free'
      },
      {
        field: 'vip',
        label: 'VIP'
      }
    ],
    tableRows: [
      { feature: 'XP Boosts', free: '❌', vip: '<span class="text-green">1.75x</span>' },
      { feature: 'Ticket Boosts', free: '❌', vip: '<span class="text-green">1.5x</span>' },
      { feature: 'Store Discount', free: '❌', vip: '<span class="text-green">10% Off</span>' },
      { feature: 'Monthly Tickets', free: '❌', vip: '<span class="text-green">Up to 6K</span>' },
      { feature: 'VIP Channels', free: '❌', vip: '✅' },
      { feature: 'VIP Role and Badge', free: '❌', vip: '✅' },
      { feature: 'Custom Embed Color', free: '❌', vip: '✅' },
      { feature: 'Role Colors', free: '❌', vip: '✅' },
      { feature: 'Featured on our <a href="/thankyou" target="_blank">Thank You</a> page', free: '❌', vip: '✅' }
    ],
    faqOpen: '',
    loaded: false,
    btInstance: null,
    guild: {},
    collapses: [
      {
        title: 'What is Clappy VIP?',
        text: 'Clappy VIP is our premium service for our custom bot. It is not required to use Clappy but it does further support our staff and development plans.'
      },
      {
        title: 'What is the money used for?',
        text: 'The money is used to further the development of Clappy with art and coding. It takes a lot of talented and hardworking individuals to run this bot and the server. You supporting Clappy VIP is keeping us motivated to keep pushing forward.'
      },
      {
        title: 'How long is a subscription?',
        text: 'Subscriptions are monthly or yearly depending on your plan. Your perks expire at the end of each month/year.'
      },
      {
        title: 'Can I stop my subscription anytime?',
        text: 'Yes you can! Once you stop your subscription, your perks will still be activated until the end of the month/year.'
      },
      {
        title: 'What payment methods are supported?',
        text: 'Right now we only support Paypal to ensure safety for our users. We don’t have access to your information through Paypal and it is protected by PayPal’s Purchase Protection.'
      }
    ]
  }),
  computed: {
    isPremium () {
      const dateStr = this.$store.state.user.user.settings.lastPremium
      if (dateStr === null) return false
      const date = new Date(dateStr)
      return date.getTime() < Date.now()
    },
    user () {
      return this.$store.state.user.user
    },
    plans () {
      return this.guild?.settings?.premiumPlans || []
    }
  },
  methods: {
    async cancel () {
      try {
        this.cancelling = true
        await cancelPremium()

        this.user.settings.nextPremiumCharge = null

        this.$buefy.notification.open({
          message: 'You will receive a DM once PayPal cancels the subscription.<br/>Your VIP perks will last until the end of the month.',
          type: 'is-success',
          duration: 20000
        })
        this.cancelling = false
      } catch (err) {
        this.$buefy.notification.open({
          message: `Error: ${err.message}`,
          type: 'is-danger'
        })
        this.cancelling = false
      }
    },
    async loadPaypal () {
      if (this.isPremium) return

      const userId = this.$store.state.user.user.id
      const paypal = await loadScript({
        'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID,
        vault: true,
        intent: 'subscription'
      })

      for (const plan of this.plans) {
        await paypal.Buttons({
          style: {
            shape: 'rect',
            color: 'blue',
            layout: 'vertical',
            label: 'subscribe'
          },
          createSubscription: function (data, actions) {
            return actions.subscription.create({
              /* Creates the subscription */
              plan_id: plan.id,
              custom_id: userId
            })
          },
          onApprove: (data, actions) => {
            this.$buefy.notification.open({
              message: 'Please wait up to 15 minutes for PayPal to propagate your VIP subscription.<br/>You will receive a DM once it activates (unless they are disabled), and you can also check status on /profile.',
              type: 'is-success',
              duration: 20000
            })
          },
          onError: (err) => {
            // TODO this
            console.log(err)
            this.$buefy.notification.open({
              message: 'Something went wrong: ' + err.message,
              type: 'is-danger'
            })
          }
        }).render(`#${plan.id}`)
      }

      // client.create({
      //   authorization: process.env.VUE_APP_BRAINTREE_TOKEN
      // }).then(function (clientInstance) {
      //   hostedFields.create(/* ... */)
      // })
    }
  },
  async mounted () {
    this.guild = await getGuild()
    if (!this.isPremium) await this.loadPaypal()
  }
}
</script>

<style lang="scss" scoped>
@import "@/sass/variables.scss";
.hero, table {
  background-image: linear-gradient(to right, #370535, #720b43, #ab2641, #d65330, #ed8a00) !important;
}

#plans .card {
  .card-header-title, .card-content {
    background-color: $ddarker !important;
  }
}
</style>

<style lang="scss">
.text-green {
  color: #7cb342 !important;
}
</style>
